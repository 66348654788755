//** Globals CORE * /

// PopUp Notifier
let NotifyTitle: string = 'Plataforma SIEMENS IPM CORE';
let NotifyMssg: string = '.....';
const Prefix: string = ', Favor verifique e intente nuevamente.';

let formx:object;
let pathx:string = '';
let path:string = '';
let urlx:string = '';

let verTimer = new Date().getMilliseconds().toString();
let qtyScript:number = scriptsCore.length;
let qtyTrack:number = 0;

function DeployScriptSecondary(
    arryScripts,
    nonce:string
) {
    let script;

    for(const key in arryScripts){
        script = document.createElement("script");
        script.type = 'text/JavaScript';

        if (arryScripts[key][2]=='module') {
            script.type = 'module';
        }

        script.setAttribute(arryScripts[key][1], ''); // Synchro
        script.setAttribute("nonce", nonce);
        script.src = arryScripts[key][0] + '?ms=' + verTimer;

        document.body.appendChild(script);
    }
}

async function LoaderScriptsAsync(
    arryScripts,
    nonce:string
) {
    for(const key in arryScripts){
        await DeployScriptCore(key, arryScripts, nonce, function(){
            qtyTrack++;
            if (qtyScript <= qtyTrack) {
                DeployScriptSecondary(scriptsSecondary, nonce);
            }
        });
    }
}

function DeployScriptCore(
    key, 
    arryScripts, 
    nonce, 
    callback
    ) {
  
    let script = document.createElement("script");

    if (arryScripts[key][2]=='m') {
        script.type = 'module';
    } else {
        script.type = 'text/JavaScript';
    }

    script.setAttribute(arryScripts[key][1], ''); // Synchro
    script.setAttribute("nonce", nonce);
    script.src = arryScripts[key][0] + '?ms=' + verTimer;
  
    // monitor script loading
    if (callback) {
        script.onreadystatechange = function () {
            if (script.readyState === "loaded" || script.readyState === "complete") {
            script.onreadystatechange = null;
            callback();
            }
        };
    
        script.onload = function () {
            callback();
        };
    }
    
    document.body.appendChild(script);
}

window.addEventListener("load", (event)=>{
    LoaderScriptsAsync(scriptsCore, nonce);
});

window.addEventListener("DOMContentLoaded", (event)=>{
});
